import { useId, useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'next-i18next';

import { ApiClient } from '@api/client';

import styles from './JoinUs.module.css';

const PIPE_URL = process.env.NEXT_PUBLIC_PIPE_MAIL_URL;

export const JoinUs = () => {
  const { t: tt } = useTranslation('join_us');

  const emailInputId = useId();

  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [subscribed, setSubscribed] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await ApiClient.post('/', { email }, null, {
        baseUrl: PIPE_URL,
      });

      let data = {};
      try {
        data = await response.json();
      } catch (error) {
        console.error('Error parsing JSON:', error);
      }

      if (response.status === 200) {
        setMessage(tt('successfully_subscribed'));
        setSubscribed(true);
      } else {
        const errorMessage = data.message || tt('unknown_error');
        setMessage(tt('error', { message: errorMessage }));
      }
    } catch (error) {
      console.error('Error during fetch:', error); // Log any error during the fetch call
    }
  };

  return (
    <section>
      <h2 className="text-center text-[24px] font-bold mt-0 mb-[32px]">
        {tt('title')}
      </h2>

      <form
        className="flex flex-col items-center gap-[24px]"
        onSubmit={handleSubmit}
      >
        <div className="relative w-full flex flex-row justify-center">
          <label className="sr-only" htmlFor={emailInputId}>
            {tt('enter_email')}
          </label>
          <input
            id={emailInputId}
            className={clsx(
              'w-full max-w-[360px]',
              'p-[12px]',
              'bg-[#222325]',
              'border border-white/50 rounded-[8px]',
              'text-[18px] leading-none font-normal text-white',
              'placeholder:text-white/30',
            )}
            type="email"
            name="email"
            autoComplete="email"
            required
            disabled={subscribed}
            placeholder={subscribed ? tt('subscribed') : tt('enter_email')}
            value={email}
            onChange={(ev) => setEmail(ev.target.value)}
          />
        </div>

        <button
          className={styles.submitButton}
          type="submit"
          disabled={subscribed}
        >
          {subscribed ? tt('thank_you') : tt('notify_me')}
        </button>
      </form>

      {message && (
        <p className="text-center text-[18px] text-white mt-[10px]">
          {message}
        </p>
      )}
    </section>
  );
};
