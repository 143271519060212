import Image from 'next/image';

export const SweetWaifuBanner = () => {
  return (
    <div>
      <p className="text-wtf-white text-center">
        Experience exclusive Anime Girlfriends and enjoy FREE messages -
        Download SweetWaifu on iOS now!
      </p>
      <div className="flex items-center justify-center space-x-4">
        <Image
          src="/sweetwaifu/app-store-badge.svg"
          width={160}
          height={60}
          alt="Download SweetWaifu on the App Store"
          className="cursor-pointer"
          onClick={() =>
            window.open(
              'https://apps.apple.com/us/app/sweetwaifu-ai-girlfriend-chat/id6584521658',
              '_blank',
            )
          }
        />
        <div className="w-[64px] h-[64px] rounded-xl border-2 border-gray-400 shadow-md flex items-center justify-center overflow-hidden">
          <Image
            src="/sweetwaifu/icon.png"
            width={56}
            height={56}
            alt="SweetWaifu App Icon"
            className="rounded-lg"
            onClick={() =>
              window.open(
                'https://apps.apple.com/us/app/sweetwaifu-ai-girlfriend-chat/id6584521658',
                '_blank',
              )
            }
          />
        </div>
      </div>
    </div>
  );
};
