import qs from 'qs';

export class ApiClient {
  static #baseUrl = process.env.NEXT_PUBLIC_BACKEND;

  static #headers = {
    'Content-Type': 'application/json',
  };

  /**
   * @param {string} url
   * @param {Object | undefined} query
   * @param {string | undefined} baseUrl
   *
   * @return {string}
   */
  static #getRequestUrl(url, query = null, baseUrl = this.#baseUrl) {
    if (!url || typeof url !== 'string' || url[0] !== '/') {
      throw new Error(
        'Provided URL is not valid! Should be string starting with `/`',
      );
    }
    if (!baseUrl || typeof baseUrl !== 'string') {
      throw new Error('Provided base URL is not valid!');
    }

    const urlToUse = url === '/' ? '' : url;
    const queryString = query ? qs.stringify(query) : '';

    return `${baseUrl}${urlToUse}${queryString ? `?${queryString}` : ''}`;
  }

  /**
   * @param {() => Promise<Response>} fetcher
   * @param {number} timeout - time in milliseconds
   *
   * @return {Promise<unknown>}
   */
  static #fetchWithTimeout(fetcher, timeout = 0) {
    return new Promise(async (resolve, reject) => {
      let timerId = undefined;

      if (timeout > 0) {
        timerId = setTimeout(() => {
          reject(new Error('Request timed out'));
        }, timeout);
      }

      try {
        const response = await fetcher();
        clearTimeout(timerId);
        resolve(response);
      } catch (e) {
        clearTimeout(timerId);
        reject(e);
      }
    });
  }

  /**
   * @param {string} url - starts with `/`
   * @param {Object | undefined} query - object with query params
   * @param {Partial<{
   *   baseUrl: string;
   *   timeout: number;
   *
   *   credentials: RequestInit['credentials'];
   *   headers: Record<string, unknown>;
   *
   *   signal?: AbortSignal;
   * }> | undefined} config
   *
   * @returns {Promise<Response>}
   */
  static get(url, query = null, config = {}) {
    return this.#fetchWithTimeout(
      () =>
        fetch(this.#getRequestUrl(url, query, config.baseUrl), {
          method: 'GET',
          credentials: config.credentials,
          headers: {
            ...this.#headers,
            ...config.headers,
          },
          signal: config.signal,
        }),
      config.timeout,
    );
  }

  /**
   * @param {string} url - starts with `/`
   * @param {Object} body - request body
   * @param {Object | undefined} query - object with query params
   * @param {Partial<{
   *   baseUrl: string;
   *   timeout: number;
   *
   *   credentials: RequestInit['credentials'];
   *   headers: Record<string, unknown>;
   *
   *   signal?: AbortSignal;
   * }> | undefined} config
   *
   * @returns {Promise<Response>}
   */
  static post(url, body, query = null, config = {}) {
    return this.#fetchWithTimeout(
      () =>
        fetch(this.#getRequestUrl(url, query, config.baseUrl), {
          method: 'POST',
          body: JSON.stringify(body),
          credentials: config.credentials,
          headers: {
            ...this.#headers,
            ...config.headers,
          },
          signal: config.signal,
        }),
      config.timeout,
    );
  }

  /**
   * @param {string} url - starts with `/`
   * @param {Object} body - request body
   * @param {Object | undefined} query - object with query params
   * @param {Partial<{
   *   baseUrl: string;
   *   timeout: number;
   *
   *   credentials: RequestInit['credentials'];
   *   headers: Record<string, unknown>;
   *
   *   signal?: AbortSignal;
   * }> | undefined} config
   *
   * @returns {Promise<Response>}
   */
  static patch(url, body, query = null, config = {}) {
    return this.#fetchWithTimeout(
      () =>
        fetch(this.#getRequestUrl(url, query, config.baseUrl), {
          method: 'PATCH',
          body: JSON.stringify(body),
          credentials: config.credentials,
          headers: {
            ...this.#headers,
            ...config.headers,
          },
          signal: config?.signal,
        }),
      config.timeout,
    );
  }
}
